<template>
  <router-view/>
</template>

<style>
*{
  margin: 0;
  padding: 0;
}
html,body,#app{
  height: 100%;
  width: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 6px;
  background-color: #ffffff;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #ffffff;
  border-radius: 10px;
  background-color: #ffffff;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px #cccccc;
  background-color: #cccccc;
}

[v-cloak]{
  display: none;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.grey{
  color: #A6A6A6;
}
.el-table {
  --el-table-header-bg-color: #F3F3F3!important;
}
.btcla-form .el-form-item__label{
  font-weight: 600;
  color: #000;
}
/*文章发布 富文本*/
.editor-div #edui1{
  min-height: 500px;
  overflow-y: auto;
}
.editor-div p ,.editor-div li{
  text-align: left;
}
.editor-div .ck-editor__main .ck-content{
  height: 600px;
}
.editor-div .card-item .image-slot .el-icon {
  font-size: 24px;
}

.editor-div .layui-form-pane .layui-form-label {
  width: 120px
}

.editor-div .layui-form-pane .layui-input-block {
  margin-left: 120px
}

.editor-div input {
  border: none;
  background: rgba(255, 255, 255, 0.1)
}

.editor-div input:focus {
  outline: medium;
}

.editor-div .edui-editor-breadcrumb {
  display: none
}

.editor-div #edui1_toolbarbox {
  background: #ffffff;
  box-shadow: 0
}

.editor-div #edui1_wordcount {
  display: none
}
.editor-div td, .editor-div th {
  padding: 5px 10px;
  border: 1px solid #DDD;
}
.articlePublishing .upload-demo .el-upload-dragger{
  height: 180px;
  padding: 0 15px;
}
.articlePublishing .upload-demo .el-upload-dragger .el-icon--upload{
  margin-top: 40px;
}
.upload-sc .el-upload-list__item{
  margin-bottom: 25px;
}
.editor-html img{
	max-width: 100%;
}
.editor-html table th {
    background-color: #f5f2f0;
    font-weight: 700;
    text-align: center;
	border: 1px solid #ccc;
	line-height: 1.5;
	min-width: 30px;
	padding: 3px 5px;
}
.editor-html table td{
	    border: 1px solid #ccc;
	    line-height: 1.5;
	    min-width: 30px;
	    padding: 3px 5px;
	    text-align: center;
}
.editor-html table{
	border-collapse: collapse;
}
.editor-html video{
	display: block;
	max-width: 100%;
	margin: 0 auto;
}
.w-e-text-placeholder{
	text-align: left;
	padding-left: 5px !important;
	color: #757575 !important;
	font-size: 18px !important;
	font-style: normal !important;
}
.articlePublishing .el-upload-list__item .el-progress{
  width: calc(100% - 30px);
}
</style>
