<template>
	<div class="editor-cent">
		<p class="editor-title">封面和摘要</p>
		<div class="cover-box">
			<div class="img-box">
				<el-image style="width: 100%; height: 100%" :src="details.thumb_media_url" fit="scale-down" />
			</div>
			<el-input
			    v-model="details.digest"
			    style="width: 55%;"
			    maxlength="120"
			    placeholder="选填，摘要会在订阅号消息、转发链接等文章外的场景显露，帮助读者快速了解内容，如不填写则默认抓取正文前54字"
			    show-word-limit
				disabled
			    :autosize="{
			      minRows: 8,maxRows: 8
			    }"
			    type="textarea"
			/>
		</div>
		<el-form  label-width="130px" style="box-sizing: border-box;padding: 15px">
			<el-form-item label="文章类型">
			  <el-input v-model="details.leixing" disabled style="width: 220px;"/>
			</el-form-item>
			<el-form-item label="年级或班级">
			  <el-input v-model="details.banji" disabled style="width: 220px;"/>
			</el-form-item>
		</el-form>
		<p class="article-title">{{details.title}}</p>
		<p class="author-title">{{details.author}}</p>
		<div class="editor-html" v-html="details.content2">
			
		</div>
	</div>
</template>

<script>
	
export default {
  name: "showDetails",
  props:{
    details:{
      type:Object,
      default(){
        return []
      }
    }
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>
	.editor-cent{
		min-height: 600px;
		padding: 20px;
		border: 1px solid #e8e8e8;
		box-sizing: border-box;
		text-align: left;
	}
	.editor-html{
		min-height: 500px;
	}
	.article-title{
		font-size: 26px;
		width: 100%;
		box-sizing: border-box;
		padding: 0 15px;
		color: #727272;
		line-height: 50px;
		margin-top: 30px;
	}
	.author-title{
		font-size: 24px;
		width: 100%;
		box-sizing: border-box;
		padding: 0 15px;
		color: #8b8686;
		line-height: 50px;
	}
	.editor-title{
		line-height: 30px;
		font-size: 18px;
		font-weight: 600;
		text-align: left;
		box-sizing: border-box;
		padding-left: 15px;
	}
	.cover-box{
		display: flex;
		width: 100%;
		height: 208px;
		padding: 15px;
		box-sizing: border-box;
		justify-content: space-between;
	}
	.img-box{
		width: 42%;
		height: 100%;
	}
</style>