<template>
  <el-row :gutter="20" style="height: 100%;" class="articlePublishing">
    <el-col :span="4">
      <div class="left-nav">
        <div class="cent">
          <section>
            <p class="left-p"
               @click="leftClick(item.title)"
               :class="{'active':leftNowTitle === item.title}"
               v-for="(item,index) in leftNavList" :key="index">{{ item.title }}</p>
          </section>
        </div>
      </div>
    </el-col>
    <el-col :span="20">
      <div class="right-cent">

        <div class="cent" v-show="leftNowTitle === '发布新内容'">
          <div class="line">发布新内容</div>
          <div class="cent-box">
            <el-row style="height: 100%">
              <el-col :span="6">

<!--                已发布列表 -->
                <el-divider content-position="left" v-if="savedList.length > 0">已保存的文章</el-divider>
                <div class="card-item yfb-card-cent" :style="{boxShadow: `var(--el-box-shadow-lighter)`}" style="margin-bottom: 15px;position: relative;overflow: hidden"
                v-for="(item,index) in savedList"
                >
<!--                  删除-->
                  <div class="yfb-hover">
                    <el-icon color="#fff" :size="26" style="margin-right: 20px" @click="switchItem(item,index)"><Edit /></el-icon>
                    <el-popconfirm
                        confirm-button-text="删除"
                        cancel-button-text="取消"
                        icon-color="#f56c6c"
                        title="您确定要删除吗?"
                        @confirm="confirmEventYfb(index)"
                    >
                      <template #reference>
                        <el-icon color="#fff" :size="26"><Delete /></el-icon>
                      </template>
                    </el-popconfirm>
                  </div>

                  <el-image style="width: 100%;height: 160px;min-width: 160px;padding: 5px;box-sizing: border-box;" :src="item.thumb_media_url" fit="scale-down" >
                    <template #error>
                      <div class="image-slot">
                        暂无封面
                        <!--                        <el-icon><iconPicture /></el-icon>-->
                      </div>
                    </template>
                  </el-image>
                  <p class="demonstration" >{{item.title}}</p>
                </div>

                <el-divider content-position="left">当前编辑的文章</el-divider>
                <div class="card-item" :style="{boxShadow: `var(--el-box-shadow-lighter)`}">
                  <el-image style="width: 100%;height: 160px;min-width: 160px;padding: 5px;box-sizing: border-box" :src="wechatFrom.thumb_media_url" fit="scale-down" >
                    <template #error>
                      <div class="image-slot">
                        暂无封面
<!--                        <el-icon><iconPicture /></el-icon>-->
                      </div>
                    </template>
                  </el-image>
                  <p class="demonstration" >{{wechatFrom.title}}</p>
                </div>

                <el-row class="mb-4" style="margin-top: 20px">
                  <el-button type="primary" style="width: 100%" @click="submitYfb('addDraft')">
                    + 新建文章
                  </el-button>
                </el-row>

              </el-col>
              <el-col :span="18" style="position: relative">
                  <div class="editor-div " id="editor-div" >

                    <div style="border: 1px solid #ccc">
                      <Toolbar
                          style="border-bottom: 1px solid #ccc"
                          :editor="editorRef"
                          :defaultConfig="toolbarConfig"
                          :mode="mode"
                      />
                      <div class="editor-container">
                        <input placeholder="点击这里输入文章标题" v-model="wechatFrom.title">
                      </div>
                      <div class="editor-container">
                        <input placeholder="点击这里输入文章作者" v-model="wechatFrom.author" style="font-size: 20px">
                      </div>
                      <Editor
                          style="min-height: 500px; overflow-y: auto;height: 500px;"
                          v-model="valueHtml"
                          :defaultConfig="editorConfig"
                          :mode="mode"
                          @onCreated="handleCreated"
                          @onFocus="handleFocus"
                      />
                      <p class="editor-title">封面和摘要</p>
                      <div style="display: flex;width: 100%;padding: 15px;box-sizing: border-box;justify-content: space-between;">
                        <el-upload
                            class="upload-demo"
                            drag
                            :http-request="importFile"
                            action="https://run.mocky.io/"
                            :show-file-list="false"
                        >
                          <el-icon class="el-icon--upload" v-if="!wechatFrom.thumb_media_url"><upload-filled /></el-icon>
                          <div class="el-upload__text" v-if="!wechatFrom.thumb_media_url">
                            拖拽或点击上传封面
                          </div>
                          <el-image style="width: 100%; height: 100%" :src="wechatFrom.thumb_media_url"
                                    fit="contain" v-if="wechatFrom.thumb_media_url"/>
                        </el-upload>

                        <el-input
                            v-model="wechatFrom.digest"
                            style="width: 55%;"
                            maxlength="120"
                            placeholder="选填，摘要会在订阅号消息、转发链接等文章外的场景显露，帮助读者快速了解内容"
                            show-word-limit
                            :autosize="{
                              minRows: 8,maxRows: 8
                            }"
                            type="textarea"
                        />
                      </div>

                      <el-form :model="wechatFrom" label-width="130px" style="box-sizing: border-box;padding: 15px">
                        <el-form-item label="文章类型">
                          <el-select v-model="wechatFrom.articleType" placeholder="选择文章类型" @change="wzlxSelect">
                            <el-option :label="item.name" :value="item.name"
                                       v-for="(item,index) in articleType" :key="item"
                            />
                          </el-select>
                        </el-form-item>
                        <el-form-item label="年级或班级">
                          <el-cascader v-model="wechatFrom.schoolClass" :options="njOptions"
                                       :props="{
                                        value:'id',
                                        label:'name',
										checkStrictly: true
                                       }"
                                       ref="cascader"
                                       @change="setClass"
                          />
                        </el-form-item>
<!--                        <el-form-item label="原文链接(非必填)">-->
<!--                          <el-input v-model="wechatFrom.content_source_url" />-->
<!--                        </el-form-item>-->
<!--                        <el-form-item label="是否打开评论">-->
<!--                          <el-radio-group v-model="wechatFrom.need_open_comment">-->
<!--                            <el-radio label="0">否</el-radio>-->
<!--                            <el-radio label="1">是</el-radio>-->
<!--                          </el-radio-group>-->
<!--                        </el-form-item>-->
<!--                        <el-form-item label="是否粉丝才可评论">-->
<!--                          <el-radio-group v-model="wechatFrom.only_fans_can_comment">-->
<!--                            <el-radio label="0">否</el-radio>-->
<!--                            <el-radio label="1">是</el-radio>-->
<!--                          </el-radio-group>-->
<!--                        </el-form-item>-->
                      </el-form>

                    </div>
                    <!-- 加载编辑器的容器 -->
<!--                    <script id="containere-ditor" name="content" type="text/plain"></script>-->
<!--                    <QuillEditor ref="myQuillEditor"-->
<!--                                 theme="snow"-->
<!--                                 v-model="content"-->
<!--                                 :options="editorOption"-->
<!--                    />-->
                  </div>
                <el-row class="mb-4" style="justify-content: flex-end;position: fixed;bottom: 50px;right: 70px">
                  <el-button type="danger" @click="resetEditor">清 空</el-button>
                  <el-button @click="submitEditor('addDraft')" :loading="isSubmit">
                    存为草稿
                  </el-button>
                  <el-button type="primary" @click="submitEditor('addArticle')" :loading="isSubmit" v-if="!nowMedia_id">发 布</el-button>
                  <el-button type="primary" plain @click="massDispatch" v-if="!nowMedia_id">群 发</el-button>
                </el-row>
              </el-col>
            </el-row>

          </div>
        </div>

        <div class="cent" v-show="leftNowTitle === '草稿箱'">
          <div class="line">草稿箱</div>
          <div class="cent-box" style="display: flex;flex-wrap: wrap;position: relative;align-content: flex-start;">
            <div class="cg-card-item card-item"
            v-for="(item,index) in draftList"
            >
              <div class="cg-card-cent" :style="{boxShadow: `var(--el-box-shadow-lighter)`}">
                <div class="cg-hover">
                  <el-icon color="#fff" :size="26" style="margin-right: 20px" @click="setDraftDetail(item)"><Edit /></el-icon>
                  <el-popconfirm
                      confirm-button-text="删除"
                      cancel-button-text="取消"
                      icon-color="#f56c6c"
                      title="您确定要删除吗?"
                      @confirm="confirmEvent(item)"
                  >
                    <template #reference>
                      <el-icon color="#fff" :size="26"><Delete /></el-icon>
                    </template>
                  </el-popconfirm>
                </div>
<!--                草稿箱hover操作栏-->

                <el-image style="width: 100%;height: 7vw;min-width: 160px;padding: 5px;box-sizing: border-box"
                          :src="item.content.news_item[0].thumb_media_url" fit="scale-down" >
                  <template #error>
                    <div class="image-slot">
                      暂无封面
                      <!--                        <el-icon><iconPicture /></el-icon>-->
                    </div>
                  </template>
                </el-image>
                <p class="demonstration" :title="item.content.news_item[0].title">{{item.content.news_item[0].title}}</p>
              </div>
            </div>
<!--            分页-->
            <div class="pagination-box">
              <el-pagination
                  v-model:current-page="currentPagecg"
                  v-model:page-size="pageLimitCg"
                  layout="total,prev, pager, next, jumper"
                  :total="cgCurrenttotal"
                  @current-change="handleCurrentChangeCg"
              />
            </div>
          </div>

        </div>

        <div class="cent" v-show="leftNowTitle === '素材库'">
          <div class="line">素材库</div>
          <div class="cent-box" style="position: relative">
            <!--            分页-->
            <div class="pagination-box">
              <el-pagination
                  v-model:current-page="currentPageSc"
                  v-model:page-size="pageLimitSc"
                  layout="total,prev, pager, next, jumper"
                  :total="ScCurrenttotal"
                  @current-change="handleCurrentChangeSc"
              />
            </div>
            <el-row class="mb-4">
              <el-button type="primary" @click="dialogVisibleSc = true">上传图片</el-button>
              <el-popconfirm
                  confirm-button-text="删除"
                  cancel-button-text="取消"
                  icon-color="#f56c6c"
                  title="您确定要删除选中的图片吗?"
                  @confirm="confirmEventSc('',1)"
              >
                <template #reference>
                  <el-button type="danger">批量删除</el-button>
                </template>
              </el-popconfirm>
            </el-row>

            <el-row class="sc-cent">
              <div class="cg-card-item card-item"
                   style="width: 16.66%;padding: 12px"
                   v-for="(item,index) in scFileList"
                   :key="index"
              >
                <div class="cg-card-cent" :style="{boxShadow: `var(--el-box-shadow-lighter)`}">
                  <!--                素材库hover操作栏 -->
                  <div class="sc-hover">
                    <el-popconfirm
                        confirm-button-text="删除"
                        cancel-button-text="取消"
                        icon-color="#f56c6c"
                        title="您确定要删除吗?"
                        @confirm="confirmEventSc(item,0)"
                    >
                      <template #reference>
                        <el-icon color="#fff" :size="26"><Delete /></el-icon>
                      </template>
                    </el-popconfirm>
                  </div>
<!--                 素材库多选按钮-->
                  <div class="sc-check"
                       :class="{'is-checked':nowCheckSc.indexOf(item.media_id) > -1}"
                       @click="checkSc(item)">
                    <span class="el-checkbox__inner"></span>
                  </div>

                  <el-image style="width: 100%;height: 7vw;padding: 5px;box-sizing: border-box"
                            :src="item.url" fit="scale-down" >
                    <template #error>
                      <div class="image-slot">
                        暂无封面
                        <!--                        <el-icon><iconPicture /></el-icon>-->
                      </div>
                    </template>
                  </el-image>
                  <p class="demonstration" :title="item.file_name">{{item.file_name}}</p>
                </div>
              </div>
            </el-row>

          </div>

        </div>
<!--        弹出-->
        <el-dialog
            v-model="dialogVisibleSc"
            title="上传文件"
            width="30%"
            :before-close="handleCloseSc"
        >

          <el-upload
              v-model:file-list="fileListSc"
              class="upload-sc"
              :http-request="importFileSc"
              action="action"
              multiple
          >
            <el-button type="primary">点击上传</el-button>
            <template #tip>
              <div class="el-upload__tip">
<!--                jpg/png files with a size less than 500KB.-->
              </div>
            </template>
          </el-upload>

          <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="dialogCloseSc">
              关闭
            </el-button>
          </span>
          </template>
        </el-dialog>

        <div class="cent" v-show="leftNowTitle === '发表记录'">
          <div class="line">发表记录</div>
          <div class="cent-box">

          </div>


        </div>


      </div>
    </el-col>
  </el-row>
<!--  素材库 插入弹窗-->
  <el-dialog
      v-model="dialogSck"
      title="素材库"
      top="50px"
      width="50%"
      height="650px"
  >
    <div class="insertLibrary-cent">
      <!--            分页-->
      <div class="pagination-box" style="bottom:-30px">
        <el-pagination
            v-model:current-page="currentPageSc"
            v-model:page-size="pageLimitSc"
            layout="total,prev, pager, next, jumper"
            :total="ScCurrenttotal"
            @current-change="handleCurrentChangeSc"
        />
      </div>
      <div class="cg-card-item card-item"
           style="width: 16.66%;padding: 12px;height: 10vw"
           v-for="(item,index) in scFileList"
           :key="index"
      >
        <div class="cg-card-cent" :style="{boxShadow: `var(--el-box-shadow-lighter)`}">
          <!--                 素材库多选按钮-->
          <div class="sc-check"
               :class="{'is-checked':inserCheckSc.media_id === item.media_id}"
               @click="inserCheckScf(item)">
            <span class="el-checkbox__inner"></span>
          </div>

          <el-image style="width: 100%;height: 6vw;padding: 5px;box-sizing: border-box"
                    :src="item.url" fit="scale-down" >
            <template #error>
              <div class="image-slot">
                暂无封面
                <!--                        <el-icon><iconPicture /></el-icon>-->
              </div>
            </template>
          </el-image>
          <p class="demonstration" :title="item.file_name">{{item.file_name}}</p>
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogSck = false">取消</el-button>
        <el-button type="primary" @click="dialogSckSubimt">
          插入
        </el-button>
      </span>
    </template>
  </el-dialog>
<!--  用于富文本 内 图片和视频链接地址替换-->
  <div id="wechat-editor" style="display: none;height: 0"></div>
</template>

<script>
// import {QuillEditor} from '@vueup/vue-quill'
// import '@vueup/vue-quill/dist/vue-quill.snow.css';

import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { ElMessage  , ElMessageBox  } from 'element-plus'
import { DomEditor , IButtonMenu , Boot } from '@wangeditor/editor'
var appThis;
export default {
  name: "articlePublishing",
  components: {
    // QuillEditor,
    Editor, Toolbar
  },
  data() {
    var that = this;
    appThis = this;
    class MyButtonMenu {
      constructor() {
        this.title = '素材库' // 自定义菜单标题
        // this.iconSvg = '<svg>...</svg>' // 可选
        this.tag = 'button'
      }
      getValue(editor) {                              // JS 语法
        return ''
      }
      isActive(editor) {                    // JS 语法
        return false
      }
      isDisabled(editor) {                     // JS 语法
        return false
      }
      exec(editor, value) {                              // JS 语法
        appThis.dialogSck = true;
        appThis.getFileList();
        // appThis.dialogSckSubimt(editor, value);
        // editor.insertText(value) // value 即 this.value(editor) 的返回值
      }
    }
    const menu1Conf = {
      key: 'sck1', // 定义 menu key ：要保证唯一、不重复（重要）
      factory() {
        return new MyButtonMenu() // 把 `YourMenuClass` 替换为你菜单的 class
      },
    }
    try {
      Boot.registerMenu(menu1Conf)
    }catch(e) {
      // console.log(e)   //Error: 出错啦！ at <anonymous>:2:8
    }
    return {
      dialogSck:false,
      dialogVisibleSc:false,
      fileListSc:[],
      njOptions:[],
      articleType:[
      ],
      wechatFrom:{
        title:'',
        author:'',
        digest:'',
        // content_source_url:'',
        // need_open_comment:'0',
        // only_fans_can_comment:'0',
        articleType:'',
        thumb_media_id:'',
        thumb_media_url:''
      },
      content: '',
      leftNavList: [
        {
          title: '发布新内容'
        },
        {
          title: '草稿箱'
        },
        {
          title: '素材库'
        },
        // {
        //   title: '发表记录'
        // }
      ],
      leftNowTitle: '发布新内容',
      toolbarConfig:{
        insertKeys:{
          index:22,
          keys:['sck1']
        },
        excludeKeys:['headerSelect']
      },
      editorConfig:{
        placeholder: '请输入内容...',
        MENU_CONF:{
          uploadImage:{
            async customUpload(file, insertFn){                   // JS 语法
            // file 即选中的文件 insertFn插入的方法 insertFn(url, alt, href)
            // 自己实现上传，并得到图片 url alt href
            // 最后插入图片
              that.updatedImg(file, insertFn)
            // insertFn(url, alt, href)
          }
          }
        }
      },
      editorRef:shallowRef(),
      mode: 'default',
      valueHtml:'',
      setClassText:'',
      isSubmit:false,
      draftList:[],
      scFileList:[],
      currentPagecg:1,
      currentPageSc:1,
      cgCurrenttotal:0,
      ScCurrenttotal:0,
      pageLimitCg:12,
      pageLimitSc:18,
      nowMedia_id : '',
      nowCheckSc:[],
      inserCheckSc:{},
      savedList:[],
      article_index:0,
      isArticleModify : false
    }
  },
  methods: {
    submitYfb(keyword){
      // console.log(this.editorRef.getHtml());
      if(!this.wechatFrom.title || !this.wechatFrom.author){
        ElMessage.error('请填写文章标题或作者！');
        return
      }else if (!this.editorRef.getHtml()){
        ElMessage.error('请填写文章内容！');
        return
      }else if (!this.wechatFrom.thumb_media_id){
        ElMessage.error('请上传文章封面');
        return
      }
      // console.log(this.editorRef.getHtml());
      var editorHtmlData =  this.editorUrltrans(this.editorRef.getHtml());
      var articleList = [
        {
          title:this.wechatFrom.title,
          author:this.wechatFrom.author,
          content:editorHtmlData,
          content2:this.editorRef.getHtml(),
          thumb_media_id:this.wechatFrom.thumb_media_id,
          thumb_media_url:this.wechatFrom.thumb_media_url,
          digest:this.wechatFrom.digest,
          schoolClass:this.setClassText,
          schoolClassId:this.wechatFrom.schoolClass,
          articleType:this.wechatFrom.articleType,
          media_id:this.nowMedia_id,
          article_index:this.article_index.toString()
        }
      ]
      if(this.isArticleModify){
        this.savedList[this.article_index] = articleList[0];
        this.isArticleModify = false;
      }else{
        this.savedList.push(articleList[0]);
      }
      console.log(this.savedList);
      if(keyword){
        //新建清空 切换只保存
        this.resetEditor();
      }
    },
    confirmEventYfb(index){
      this.savedList.splice(index,1);
      // var nowSaved = JSON.parse(JSON.stringify(this.savedList));
      // nowSaved.splice(index,1);
    },
    dialogSckSubimt(editor, value){
      this.dialogSck = false;
      var edHtml = `<img src="${this.inserCheckSc.url}" data-href="${this.inserCheckSc.wx_url}" style=""/>`;
      this.editorRef.focus();
      this.editorRef.dangerouslyInsertHtml(edHtml);
      this.inserCheckSc = {};
    },
    checkSc(item){
      var nowIndex = this.nowCheckSc.indexOf(item.media_id);
      if(nowIndex > -1){
      //  已存在 移除
        this.nowCheckSc.splice(nowIndex,1);
      }else {
      //  添加
        this.nowCheckSc.push(item.media_id)
      }
      console.log(this.nowCheckSc)
    },
    inserCheckScf(item){
      this.inserCheckSc = item
    },
    dialogCloseSc(){
      this.dialogVisibleSc = false;
      this.fileListSc = []
    },
    handleCloseSc(done){
      this.fileListSc = [];
      done();
    },
    setClass(val){
      for (let i = 0; i < this.njOptions.length; i++) {
        if(this.njOptions[i].id === val[0]){
          this.setClassText = this.njOptions[i].name;
          if(this.njOptions[i].children && val.length === 2){
            this.setClassText += "/" + this.$refs["cascader"].getCheckedNodes()[0].label;
          }
        }
      }
    },
    //图片上传
    importFile(params){
      requestfaceUploadFilefn("wechat/upload.php","uploadfile",{keyword:'uploadfile'},"01",params.file).then((data)=>{
        //数据返回成功
        if(data.success){
          this.wechatFrom.thumb_media_url = data.data.url;
          this.wechatFrom.thumb_media_id = data.data.media_id;
          // console.log(this.leftTreeList)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    //素材库上传
    importFileSc(params){
      requestfaceUploadFilefn("wechat/upload.php","uploadfile",{keyword:'uploadfile'},"01",params.file).then((data)=>{
        //数据返回成功
        if(!data.success){
          // console.log(this.leftTreeList)
          return
        }
        params.file.percent = 100;
        params.onProgress(params.file)
      }).catch((result=>{
        //数据返回失败
      }));
    },
    progressSc(evt,uploadFile,uploadFiles){
      // console.log(evt)
      // console.log(uploadFile)
      // console.log(uploadFiles)
    },
    getArticlieType(){
      requestfaceWithHeadersfn("wechat/index.php","articleType",{
        keyword:'articleType',
      },"01").then((data)=>{
        //数据返回成功
        if(data.success){
          this.articleType =  data.data.list;
          // console.log(this.leftTreeList)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    wzlxSelect(val){
      var ischild = '1';
      requestfaceWithHeadersfn("wechat/index.php","getSchoolClass",{
        keyword:'getSchoolClass',
        child:ischild,
        parent_id:'0'
      },"01").then((data)=>{
        //数据返回成功
        if(data.success){
          this.njOptions =  data.data.list;
          // console.log(this.leftTreeList)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    leftClick(title) {
      this.resetEditor();
      this.leftNowTitle = title;
      this.savedList = [];
      this.article_index = 0;
      this.isArticleModify = false;
      if(title === '草稿箱'){
        this.getDraftList();
      }else if(title === '素材库'){
        this.nowCheckSc = [];
        this.currentPageSc = 1;
        this.ScCurrenttotal = 0;
        this.getFileList();
      }
    },
    setup(){
      // 编辑器实例，必须用 shallowRef
      this.editorRef = shallowRef()
      // 内容 HTML
    },
    handleCreated(editor){
      this.editorRef = editor // 记录 editor 实例，重要！
      // console.log(editor)
      // editor.insertText('xxx1');
      // this.valueHtml = `11`
      // editor.dangerouslyInsertHtml(`9999`)
      // console.log(editor.getHtml())
      // console.log(editor.getSelectionText())
    },
    handleFocus(editor){

      // console.log(editor.getHtml())
    },
    updatedImg(file,insertFn) {
      var fileName = file.name;
      requestfaceUploadFilefn("wechat/upload.php","uploadfile",{keyword:'uploadfile'},"01",file).then((data)=>{
        //数据返回成功
        if(data.success){
          insertFn(data.data.url, fileName, data.data.wx_url)
          // console.log(this.leftTreeList)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    //清空
    resetEditor(){
      this.editorRef.clear();
      this. wechatFrom = {
        title:'',
        author:'',
        digest:'',
        articleType:'',
        thumb_media_id:'',
        thumb_media_url:''
      }
      this.setClassText = '';
      this.nowMedia_id = '';
      this.isArticleModify = false;
    },
    //群发
    massDispatch(){
      ElMessageBox.confirm(
          `订阅号提供了每天一条的群发权限,服务号提供每月（自然月）4条的群发权限。`,
          '群发次数限制',
          {
            confirmButtonText: '确定发送',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            this.submitEditor('massArticle');
          })
          .catch(() => {
          })
    },
    submitEditor(keyword){
      // console.log(this.editorRef.getHtml());
      if(!this.wechatFrom.title || !this.wechatFrom.author){
        ElMessage.error('请填写文章标题或作者！');
        return
      }else if (!this.editorRef.getHtml()){
        ElMessage.error('请填写文章内容！');
        return
      }else if (!this.wechatFrom.thumb_media_id){
        ElMessage.error('请上传文章封面');
        return
      }
      this.isSubmit = true;
       // console.log(this.editorRef.getHtml());
       var editorHtmlData =  this.editorUrltrans(this.editorRef.getHtml());
      var articleList = [] ;
      if(this.savedList.length > 0){
        articleList = JSON.parse(JSON.stringify(this.savedList));
      }
      //修改的时候 直接赋值，新建的时候追加数组
      var nowArticleData = {
        title:this.wechatFrom.title,
        author:this.wechatFrom.author,
        content:editorHtmlData,
        content2:this.editorRef.getHtml(),
        thumb_media_id:this.wechatFrom.thumb_media_id,
        thumb_media_url:this.wechatFrom.thumb_media_url,
        digest:this.wechatFrom.digest,
        schoolClass:this.setClassText,
        schoolClassId:this.wechatFrom.schoolClass,
        articleType:this.wechatFrom.articleType,
        media_id:this.nowMedia_id,
        article_index:this.article_index.toString()
      }
      console.log(this.isArticleModify)
      if(this.isArticleModify){
          articleList[this.article_index] = nowArticleData;
          this.isArticleModify = false;
      }else{
        articleList.push(nowArticleData);
      }
      requestfaceWithHeadersfn("wechat/index.php",keyword,{
        keyword:keyword,
        articles:JSON.stringify(articleList)
      },"01").then((data)=>{
        this.savedList = [];
        this.isSubmit = false;
        //数据返回成功
        if(!data.success){
          ElMessage.error(data.msg);
          return
          // console.log(this.leftTreeList)
        }

        // if(this.isArticleModify){
        //   this.savedList[this.article_index] = articleList[0];
        //   this.isArticleModify = false;
        // }else{
        //   this.savedList.push(articleList[0]);
        // }
        console.log(this.savedList);
        //清空
        this.resetEditor();
        ElMessage.success(data.msg);
      }).catch((result=>{
        this.isSubmit = false;
        ElMessage.error('服务器繁忙');
        //数据返回失败
      }));
    },
    // url替换成微信url
    editorUrltrans(html){
      $('#wechat-editor').html(html);
      var imgList = $('#wechat-editor img') || [];
      console.log(imgList);
      if(imgList.length > 0){
        for (let i = 0; i < imgList.length; i++) {
          $(imgList[i]).attr('src',$(imgList[i]).attr('data-href'));
          $(imgList[i]).css('margin','10px');
        }
      }
      return $('#wechat-editor').html();
    },
    //获取草稿列表
    getDraftList(){
      requestfaceWithHeadersfn("wechat/index.php","getDraftList",{
        keyword:'getDraftList',
        limit:this.pageLimitCg.toString(),
        page:this.currentPagecg.toString()
      },"01").then((data)=>{
        //数据返回成功
        if(data.success){
          this.draftList = data.data.item;
          this.cgCurrenttotal = data.data.total_count;
          // console.log(this.leftTreeList)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    //素材库分页
    handleCurrentChangeSc(){
    this.getFileList()
    },
    //获取素材库列表
    getFileList(){
      requestfaceWithHeadersfn("wechat/index.php","getFileList",{
        keyword:'getFileList',
        limit:this.pageLimitSc.toString(),
        page:this.currentPageSc.toString()
      },"01").then((data)=>{
        //数据返回成功
        if(data.success){
          this.scFileList = data.data.item;
          this.ScCurrenttotal = data.data.total_count;
          // console.log(this.leftTreeList)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    //草稿分页
    handleCurrentChangeCg(){
      this.getDraftList();
    },
    //草稿详情
    setDraftDetail(item){
      this.nowMedia_id = item.media_id;
      this.leftNowTitle = '发布新内容';
      requestfaceWithHeadersfn("wechat/index.php","getDraftDetail",{
        keyword:'getDraftDetail',
        media_id:item.media_id
      },"01").then((data)=>{
        //数据返回成功
        if(!data.success){
          ElMessage.error(data.msg);
          return
          // console.log(this.leftTreeList)
        }
        console.log(data)
        ElMessage.success(data.msg);
        var dataList = data.data.news_item[0];
        this.article_index = 0;
        this.isArticleModify = true;
        if (data.data.news_item.length > 1){
          this.savedList = data.data.news_item;
          this.savedList[0].media_id = item.media_id;
        }else{
          this.savedList = [];
        }
        this.wechatFrom = {
          title:dataList.title,
          author:dataList.author,
          digest:dataList.digest,
          articleType:dataList.articleType,
          thumb_media_id:dataList.thumb_media_id,
          thumb_media_url:dataList.thumb_media_url
        }
        this.editorRef.setHtml(dataList.content);
        this.wzlxSelect(dataList.articleType);
        this.wechatFrom.schoolClass = dataList.schoolClassId;
        this.setClassText = dataList.schoolClass;
      }).catch((result=>{
        ElMessage.error('服务器繁忙');
        //数据返回失败
      }));
    },
    // 发布内容左侧点击切换
    switchItem(dataList,index){
      this.submitYfb();
      console.log(dataList)
      this.article_index = index;
      this.isArticleModify = true;
      this.wechatFrom = {
        title:dataList.title,
        author:dataList.author,
        digest:dataList.digest,
        articleType:dataList.articleType,
        thumb_media_id:dataList.thumb_media_id,
        thumb_media_url:dataList.thumb_media_url
      };
      this.editorRef.setHtml(dataList.content);
      this.wzlxSelect(dataList.articleType);
      this.wechatFrom.schoolClass = dataList.schoolClassId;
      this.setClassText = dataList.schoolClass;
    },
    //删除草稿
    confirmEvent(item){
      requestfaceWithHeadersfn("wechat/index.php","delDraft",{
        keyword:'delDraft',
        media_id:item.media_id
      },"01").then((data)=>{
        console.log(data)
        //数据返回成功
        if(!data.success){
          ElMessage.error(data.msg);
          return
          // console.log(this.leftTreeList)
        }
        ElMessage.success(data.msg);
        this.getDraftList();
      }).catch((result=>{
        ElMessage.error(data.msg);
        //数据返回失败
      }));
    },
  //  删除素材库 num 是否为数组删除 0 是数组 1单个删除
    confirmEventSc(item,num){
      console.log(item);
      var media_id = '';
      if(num ===0){
        media_id = item.media_id;
      }else {
        if(this.nowCheckSc.length === 0){
          ElMessage.error('请选择要删除的图片');
          return
        }
        media_id = this.nowCheckSc;
      }
      requestfaceWithHeadersfn("wechat/index.php","delFile",{
        keyword:'delFile',
        media_id:media_id
      },"01").then((data)=>{
        console.log(data)
        //数据返回成功
        if(!data.success){
          ElMessage.error(data.msg);
          return
          // console.log(this.leftTreeList)
        }
        ElMessage.success(data.msg);
        this.getFileList();
      }).catch((result=>{
        ElMessage.error(data.msg);
        //数据返回失败
      }));
    }
  },
  mounted() {
    this.setup();
    //获取文章类型
    setTimeout(()=>{
      this.getArticlieType();
    },1000)

  },
  beforeUnmount(){
    const editor = this.editorRef
    console.log(editor)
    if (editor == null) return
    editor.destroy()
  }
}
</script>

<style scoped>
.editor-div {
  width: 100%;
  height: calc(100% - 120px);
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 20px;
}

.left-nav {
  width: 100%;
  height: 100%;
}

.cent {
  width: 100%;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
}

.left-p {
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  cursor: pointer;
  padding: 0 15px;
  border-radius: 10px;
}

.left-p.active {
  background-color: #ECF2FE;
  border-radius: 5px;
}

.right-cent {
  width: 100%;
  height: 100%;
  padding-bottom: 15px;
}

.line {
  width: 100%;
  border-bottom: 1px solid #E7E7E7;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  padding-bottom: 15px;
  font-weight: bold;
  color: #333333;
  font-size: 15px;
}

.cent-box {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-top: 50px;
  box-sizing: border-box;
}
.demonstration{
  font-weight: bold;
  color: #333333;
  font-size: 14px;
  line-height: 40px;
  text-align: left;
  padding-left: 15px;
  box-sizing: border-box;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}
.card-item .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 24px;
}
.editor-container{
  padding: 20px 0;
  /*border-bottom: 1px solid #e8e8e8;*/
}
.editor-container input{
  font-size: 24px;
  border: 0;
  outline: none;
  width: 100%;
  line-height: 1;
  box-sizing: border-box;
  padding: 0 15px;
}
.editor-title{
  line-height: 30px;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  box-sizing: border-box;
  padding-left: 15px;
}
.upload-demo{
  width: 42%;
}
.cg-card-item{
  width: 25%;
  height: 12vw;
  padding: 25px;
  box-sizing: border-box;
}
.cg-card-cent{
  width: 100%;
  position: relative;
  overflow: hidden;
}
.cg-card-cent:hover .cg-hover{
  transform: translateY(0);
}
.cg-card-cent:hover .sc-hover{
  transform: translateY(0);
}
.sc-hover{
  width: 100%;
  height: 25%;
  transition: .5s;
  background: rgba(0,0,0,.3);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(101%);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sc-check{
  width: 18px;
  height: 18px;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10;
  --el-checkbox-font-size: 14px;
  --el-checkbox-font-weight: var(--el-font-weight-primary);
  --el-checkbox-text-color: var(--el-text-color-regular);
  --el-checkbox-input-height: 14px;
  --el-checkbox-input-width: 14px;
  --el-checkbox-border-radius: var(--el-border-radius-small);
  --el-checkbox-bg-color: var(--el-fill-color-blank);
  --el-checkbox-input-border: var(--el-border);
  --el-checkbox-disabled-border-color: var(--el-border-color);
  --el-checkbox-disabled-input-fill: var(--el-fill-color-light);
  --el-checkbox-disabled-icon-color: var(--el-text-color-placeholder);
  --el-checkbox-disabled-checked-input-fill: var(--el-border-color-extra-light);
  --el-checkbox-disabled-checked-input-border-color: var(--el-border-color);
  --el-checkbox-disabled-checked-icon-color: var(--el-text-color-placeholder);
  --el-checkbox-checked-text-color: var(--el-color-primary);
  --el-checkbox-checked-input-border-color: var(--el-color-primary);
  --el-checkbox-checked-bg-color: var(--el-color-primary);
  --el-checkbox-checked-icon-color: var(--el-color-white);
  --el-checkbox-input-border-color-hover: var(--el-color-primary);
}
.sc-check .el-checkbox__inner{
  width: 100%;
  height: 100%;
  border-radius:2px;
  background-color:#fff;
  cursor: pointer;
}
.el-checkbox__inner:after {
  box-sizing: content-box;
  content: "";
  border: 1px solid var(--el-checkbox-checked-icon-color);
  border-left: 0;
  border-top: 0;
  height: 10px;
  left: 6px;
  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);
  width: 3px;
  transition: transform .15s ease-in .05s;
  transform-origin: center;
}
.sc-check.is-checked .el-checkbox__inner:after {
  transform: rotate(45deg) scaleY(1);
}
.sc-check.is-checked .el-checkbox__inner {
  background-color: var(--el-checkbox-checked-bg-color);
  border-color: var(--el-checkbox-checked-input-border-color);
}
.cg-hover{
  width: 100%;
  height: 30%;
  transition: .5s;
  background: rgba(0,0,0,.3);
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-101%);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yfb-hover{
  width: 100%;
  height: 25%;
  transition: .5s;
  background: rgba(0,0,0,.6);
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-101%);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yfb-card-cent:hover .yfb-hover{
  transform: translateY(0);
}
.sc-hover .el-icon,.cg-hover .el-icon ,.yfb-hover .el-icon{
  cursor: pointer;
}
.pagination-box {
  position: absolute;
  right: 10px;
  bottom: 20px;
  z-index: 99;
}
.sc-cent{
  height: calc(100% - 40px);
  align-content: flex-start;
}
.insertLibrary-cent{
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;
  align-content: flex-start;
  margin-bottom: 20px;
}
</style>