<template>
  <div class="content">
    <p class="title-p">首页</p>
    <el-row :gutter="20">
      <el-col :span="15">

        <div class="item-flex" style="height: 150px;background: none">

            <div class="item-flex top-item">
              <div style="text-align: left">
                <p class="top-title">{{statisticsList.current_month_publish || 0}}</p>
                <p style="color: #333333;margin-top: 11px">本月发布文章数</p>
                <div style="color: #999999;font-size: 14px;font-weight: 500;margin-top: 5px">
                  <span style="color: #0052D9" v-cloak>
                    {{differenceTwoNumbers(statisticsList.current_month_publish,statisticsList.last_month_publish)}}
                  </span>
                  <span style="margin-left: 15px">比上个月</span>
                </div>
              </div>
              <img src="/img/homeIndex/text.png" style="height: 70px">
            </div>

          <div class="item-flex top-item">
            <div style="text-align: left">
              <p class="top-title">{{statisticsList.current_week_proposal || 0}}</p>
              <p style="color: #333333;margin-top: 11px">本周投诉建议数</p>
              <div style="color: #999999;font-size: 14px;font-weight: 500;margin-top: 5px">
                <span style="color: #0052D9" v-cloak>
                  {{differenceTwoNumbers(statisticsList.current_week_proposal,statisticsList.last_week_proposal)}}
                </span>
                <span style="margin-left: 15px">比上个月</span>
              </div>
            </div>
            <img src="/img/homeIndex/tsjy.png" style="height: 70px">
          </div>

          <div class="item-flex top-item">
            <div style="text-align: left">
              <p class="top-title">{{statisticsList.current_mohth_noreply || 0}}</p>
              <p style="color: #333333;margin-top: 11px">投诉建议未回复</p>
              <div style="color: #999999;font-size: 14px;font-weight: 500;margin-top: 5px">
                <span style="color: #0052D9" v-cloak>
                  {{differenceTwoNumbers(statisticsList.current_mohth_noreply,statisticsList.last_mohth_noreply)}}
                </span>
                <span style="margin-left: 15px">比上个月</span>
              </div>
            </div>
            <img src="/img/homeIndex/whf.png" style="height: 70px">
          </div>

        </div>

        <div class="item-card" style="height: 410px;margin-top: 20px">
            <div id="echart-ts" style="width: 100%;height: 100%"></div>
        </div>

        <div class="item-card" style="height: 200px;margin-top: 20px">
          <div class="item-title">我的常用</div>
          <div class="img-box">
            <div class="img-item" v-for="(item,index) in wdcyList" :key="index">
              <img :src="item.url">
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="9">

        <div class="item-card" style="height: 345px">
          <div class="item-flex">
            <div class="item-title" style="margin-bottom: 0">最新发布</div>
<!--            <div class="item-more">更多>></div>-->
          </div>
          <div class="item-content">
            <div class="text-flex" v-for="(itme,index) in systemNotificationList" :key="index">
               <p class="demonstration">
                 {{itme.title}}
               </p>
              <p class="grey">
                {{itme.success_time}}
              </p>
            </div>
          </div>
        </div>

        <div class="right-date">

          <div class="right-title">
            <p @click="getNewData" style="font-weight: bold;font-size: 18px;">备忘录</p>
            <div>
              <button class="el-button el-button--text el-button--small" type="button" @click="addBwl">
                <span style="color: #2968D4;font-size: 16px;">新增内容</span>
              </button>
              <!-- <button class="el-button el-button--text el-button--small" type="button">
                <span style="color: #2968D4;font-size: 16px;">更多</span>
              </button> -->
            </div>
          </div>

          <el-table :data="rightDate" style="width: 100%;margin-top: 15px;" @row-dblclick="bwlDblclick">
            <el-table-column label="事项" show-overflow-tooltip>
              <template #default="scope">
                <div style="display: flex; align-items: center">
                  <div class="circular-box" :class="{
							 'circular-red':scope.row.state == 4,
							 'circular-yelo':scope.row.state == 3,
							 'circular-gree':scope.row.state == 2,
						 }"></div>
                  <span>{{scope.row.content}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="add_time" label="创建时间" width="110" >
              <template #default="scope">
                <span>{{scope.row.add_time.slice(0,11)}}</span>
              </template>
            </el-table-column>
            <el-table-column  label="操作" width="80">
              <template #default="scope">
                <div style="display: flex; align-items: center">
                  <el-popconfirm title="确定要删除吗?" @confirm="delBwl(scope.row)">
                    <template #reference>
                      <button class="el-button el-button--text el-button--small" type="button">
                        <span style="color: #f56c6c;">删除</span>
                      </button>
                    </template>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div style="margin-top: 15px;">
            <button class="el-button el-button--text el-button--small" type="button" @click="moreBwl">
              <span style="color: #2968D4;font-size: 16px;">更多</span>
            </button>
          </div>

        </div>


      </el-col>
    </el-row>



  </div>

<!--  添加备忘录-->
  <el-dialog v-model="dialogTableRemarks" title="备注" width="25%" :close-on-click-modal="false">
    <div class="tool-item department-creat" style="width: 100%;height: auto;align-items: flex-start;" >
      <div class="tool-label" style="width:100px">
        事项内容：
      </div>
      <el-input v-model="remarksConet"  type="textarea" :autosize="{ minRows: 4}" placeholder="请输入事项内容" />
    </div>
    <div class="tool-item department-creat" style="width: 100%;" >
      <div class="tool-label" style="width:100px">
        重要程度：
      </div>
      <el-select v-model="noteState" class="m-2" placeholder="选择账号重要程度" style="width: 100%;"
      >
        <el-option
            v-for="item in roleList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
        >
          <div style="display: flex; align-items: center">
            <div class="circular-box circular-red" style="margin-right: 10px;"
                 :class="{
							 'circular-red':item.value == 4,
							 'circular-yelo':item.value == 3,
							 'circular-gree':item.value == 2,
						 }" ></div>
            <span>{{item.label}}</span>
          </div>
        </el-option>
      </el-select>
    </div>
    <div style="width: 100%;display: flex;justify-content: flex-end;margin-top: 20px;">
      <el-button  @click="dialogTableRemarks = false">取 消</el-button>
      <el-button type="primary" color="#2968D4" @click="submitRemarks" :loading="isSubmit">提 交</el-button>
    </div>
  </el-dialog>
<!--  更多备忘录-->
  <el-dialog v-model="dialogTableMore" title="更多" width="35%" :close-on-click-modal="false">
    <el-table :data="bwlDate" style="width: 100%;margin-top: 15px;min-height:400px" @row-dblclick="bwlDblclick">
      <el-table-column label="事项" show-overflow-tooltip>
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <div class="circular-box" :class="{
					 'circular-red':scope.row.state == 4,
					 'circular-yelo':scope.row.state == 3,
					 'circular-gree':scope.row.state == 2,
				 }"></div>
            <span>{{scope.row.content}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="add_time" label="创建时间" width="160" >
        <template #default="scope">
          <span>{{scope.row.add_time}}</span>
        </template>
      </el-table-column>
      <el-table-column  label="操作" width="80">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-popconfirm title="确定要删除吗?" @confirm="delBwl(scope.row)">
              <template #reference>
                <button class="el-button el-button--text el-button--small" type="button">
                  <span style="color: #f56c6c;">删除</span>
                </button>
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        v-model:currentPage="bwlPage"
        v-model:page-size="bwlLimit"
        layout="prev, pager, next, jumper"
        :total="bwlTotal"
        @current-change="handleCurrentChange"
        style="justify-content: flex-end;"
    />

  </el-dialog>
</template>

<script>
import * as echarts from 'echarts';
import {
  ElMessage
} from 'element-plus'
export default {
  name: "homeIndex",
  data() {
    this.myChart = null;
    return {
      wdcyList:[
        {
          url:'/img/homeIndex/icon0.png',
          name:'活动发布'
        },
        {
          url:'/img/homeIndex/icon1.png',
          name:'作文发布'
        },
        {
          url:'/img/homeIndex/icon2.png',
          name:'优秀表彰'
        },
        {
          url:'/img/homeIndex/icon3.png',
          name:'班级风貌'
        },
        {
          url:'/img/homeIndex/icon4.png',
          name:'投诉建议'
        }
      ],
      systemNotificationList:[],
      isAdd:true,
      dialogTableRemarks:false,
      remarksConet:'',
      noteState:'3',
      roleList:[
        {
          value:'2',
          label:'一般'
        },
        {
          value:'3',
          label:'重要'
        },
        {
          value:'4',
          label:'紧急'
        }
      ],
      nowBwlItem:{},
      rightDate:[],
      bwlPage:1,
      bwlLimit:8,
      bwlDate:[],
      bwlTotal:0,
      dialogTableMore:false,
      isSubmit:false,
      statisticsList:{},
      user_info:JSON.parse(sessionStorage.getItem('ucode')) || {}
    }
  },
  methods: {
    handleCurrentChange(page){
      this.bwlPage = page;
      this.moreBwlData();
    },
    getNews(){
      vaildInterface("wechat/api.php","last_news",{
        keyword:'last_news',
      },"01").then((data)=>{
        //数据返回成功
        if(data.success){
          this.systemNotificationList = data.data.data;
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    getProposalchart(){
      vaildInterface("wechat/api.php","six_month_proposal",{
        keyword:'six_month_proposal',
      },"01").then((data)=>{
        //数据返回成功
        if(data.success){
          this.setChart(data.data.data);
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    differenceTwoNumbers(now,last){
      return parseInt(now) - parseInt(last) || 0;
    },
    submitRemarks(){
      this.isSubmit = true;
      if(this.isAdd){

        vaildInterface("wechat/api.php","addNote",{
          keyword:'addNote',
          user_id:this.user_info.user_id,
          content:this.remarksConet,
          state:this.noteState
        },"01").then((data)=>{
          this.isSubmit = false;
          //数据返回成功
          if (!data.success) {
            ElMessage.error(data.msg);
            return
          }
          ElMessage.success(data.msg);
          this.dialogTableRemarks = false;
          this.getBwlData();
          // this.moreBwlData();
        }).catch((result=>{
          this.isSubmit = false;
          //数据返回失败
        }));
      }else{

        vaildInterface("wechat/api.php","editNote",{
          keyword:'editNote',
          user_id:this.user_info.user_id,
          content:this.remarksConet,
          state:this.noteState,
          id:this.nowBwlItem.id
        },"01")
            .then(res => {
              this.isSubmit = false;
              if (!res.success) {
                ElMessage.error(res.msg);
                return
              }
              ElMessage.success(res.msg);
              this.dialogTableRemarks = false;
              this.getBwlData();
              this.moreBwlData();
              // 用户列表
            })
            .catch(error => {
              this.isSubmit = false;
              console.error('error:', error)
            })
      }
    },
    moreBwl(){
      this.dialogTableMore = true;
      this.moreBwlData();
    },
    moreBwlData(){
      vaildInterface("wechat/api.php","noteList",{
        keyword:'noteList',
        user_id:this.user_info.user_id,
        limit:this.bwlLimit.toString(),
        page:this.bwlPage.toString()
      },"01")
          .then(res => {
            if (!res.success) {
              ElMessage.error(res.data.msg);
              return
            }
            this.bwlDate = res.data.list;
            this.bwlTotal = parseInt(res.data.total);
            // 用户列表
          })
          .catch(error => {
            console.error('error:', error)
          })
    },
    //默认条数
    getBwlData(){
      vaildInterface("wechat/api.php","noteList",{
        keyword: 'noteList',
        user_id:this.user_info.user_id,
        limit:'7',
        page:'1'
      },"01").then((data)=>{
        //数据返回成功
        if(data.success){
          this.rightDate =  data.data.list;
          // console.log(this.leftTreeList)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    getStatistics(){
      vaildInterface("wechat/api.php","statistics",{
        keyword:'statistics',
      },"01").then((data)=>{
        //数据返回成功
        if(data.success){
          this.statisticsList =  data.data.nums;
          // console.log(this.leftTreeList)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    delBwl(data){
      vaildInterface("wechat/api.php","delNote",{
        keyword: 'delNote',
        id:data.id
      },"01")
          .then(res => {
            if (!res.success) {
              ElMessage.error(res.msg);
              return
            }
            this.getBwlData();
            this.moreBwlData();
            // 用户列表
          })
          .catch(error => {
            console.error('error:', error)
          })
    },
    bwlDblclick(row, column, event){
      this.isAdd = false;
      this.noteState = row.state;
      this.remarksConet = row.content;
      this.nowBwlItem = row;
      this.dialogTableRemarks = true;
    },
    addBwl(){
      this.isAdd = true;
      this.dialogTableRemarks = true;
      this.remarksConet = '';

    },
    setChart(data) {
      let bgColor = "#fff";
      let color = [
        "#FF9542",
        "#36CE9E",
        "#FFC005",
        "#FF515A",
        "#8B5CFF",
        "#00CA69"
      ];
      // let xAxisData = ["1", "2", "3", "4", "5", "6", "7", "8"]; // x轴日期格式
      // let yAxisData1 = [100, 138, 350, 173, 180, 150, 180, 230]; // y轴数据格式
      // let yAxisData2 = [233, 233, 200, 180, 199, 233, 210, 180];
      let xAxisData = [];
      let yAxisData1 = [];
      // let yAxisData2 = [];
      for (var i = 0; i < data.length; i++) {
        xAxisData.push(data[i].year_month);
        yAxisData1.push(data[i].count);
      }
      const hexToRgba = (hex, opacity) => {
        let rgbaColor = "";
        let reg = /^#[\da-f]{6}$/i;
        if (reg.test(hex)) {
          rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
              "0x" + hex.slice(3, 5)
          )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
        }
        return rgbaColor;
      }
      // 基于准备好的dom，初始化echarts实例
      this.myChart = echarts.init(document.getElementById('echart-ts'));

      // 指定图表的配置项和数据
      var option = {
        // backgroundColor: bgColor,
        color: color,
        legend: {
          right: 10,
          top: 10
        },
        tooltip: {
          trigger: "axis",
          formatter: function(params) {
            let html = '';
            params.forEach(v => {
              // console.log(v);
              html += `<div style="color: #666;font-size: 14px;line-height: 24px">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color[v.componentIndex]};"></span>
                ${v.seriesName}-${v.name}
                <span style="color:${color[v.componentIndex]};font-size: 18px">${v.value}</span>
                个`;
            })
            return html
          },
          extraCssText: 'background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;',
        },
        grid: {
          top: '25%',
          left: '5%',
          right: '5%',
          bottom: '8%',
          containLabel: true
        },
        xAxis: [{
          type: "category",
          boundaryGap: false,
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: "#333"
            }
          },
          axisLine: {
            lineStyle: {
              color: "#D9D9D9"
            }
          },
          data: xAxisData
        }],
        yAxis: [{
          type: "value",
          name: '投诉建议趋势统计',
          nameGap: 55,
          axisLabel: {
            textStyle: {
              color: "#666"
            }
          },
          nameTextStyle: {
            color: "#7D86B3",
            fontSize: 12,
            lineHeight: 40
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#E9E9E9"
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        }],
        series: [{
          name: "投诉建议数",
          type: "line",
          smooth: true,
          // showSymbol: false,/
          symbolSize: 8,
          zlevel: 3,
          lineStyle: {
            color: color[0],
            shadowBlur: 3,
            shadowColor: hexToRgba(color[0], 0.5),
            shadowOffsetY: 8
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [{
                  offset: 0,
                  color: hexToRgba(color[0], 0.3)
                },
                  {
                    offset: 1,
                    color: hexToRgba(color[0], 0.1)
                  }
                ],
                false
            ),
            shadowColor: hexToRgba(color[0], 0.1),
            shadowBlur: 10
          },
          data: yAxisData1
        }]
      };

      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
    }
  },
  mounted() {
    this.getStatistics();
    //统计表格
    this.getProposalchart();
    //最新发布
    this.getNews();
  //  备忘录
    this.getBwlData();
  }
}
</script>

<style scoped>
.content{
}
.item-title{
  font-weight: bold;
  color: #0052D9;
  border-left: 3px solid #0052D9;
  box-sizing: border-box;
  padding-left: 10px;
  text-align: left;
  margin-bottom: 18px;
}
.item-card{
  padding: 15px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 5px;
}
.img-box{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.top-title{
  font-weight: bold;
  color: #333333;
  font-size: 45px;
}
.top-item{
  width: 32%;
  height: 100%;
  align-items: center;
  background: rgb(255, 255, 255);
  box-sizing: border-box;
  padding: 0px 30px;
  min-width: 250px;
  border-radius: 3px;
}
.img-item{
  cursor: pointer;
}
.img-item>img{
  width: 5.3vw;
  height: 5.3vw;
  margin-bottom: 8px;
}
.item-flex{
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 8px;
}
.item-more{
  color: #0052D9;
  cursor: pointer;
}
.item-content{
  width: 100%;
  height: calc(100% - 21px);
  overflow: hidden;
}
.text-flex{
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: rgba(0,0,0,0.9);
  border-bottom: #A6A6A6 solid 1px;
  padding: 10px 24px;
}
.right-date {
  width: 100%;
  height: 435px;
  background-color: #ffffff;
  border-radius: 5px;
  margin: 0 auto;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.circular-box{
  width:8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}
.circular-red{
  background-color: #FF0000;
}
.circular-yelo{
  background-color: #FFA000;
}
.circular-gree{
  background-color: #00FF13;
}
.right-title{
  width: 100%;
  display: flex;
  font-size: 20px;
  justify-content: space-between;
}
.tool-item {
  width: 30%;
  height: 48px;
  display: flex;
  align-items: center;
  margin-right: 50px;
}
.demonstration{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}
</style>