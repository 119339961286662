<template>
  <template v-for="(item) in menuList">
    <el-sub-menu v-if="item.children && item.children.length>0"   :key="item.id" :index="item.id">
      <template #title>
        <span v-html="item.icon" v-if="item.icon"></span>
<!--        <el-icon>-->
<!--          <component :is="item.icon"></component>-->
<!--        </el-icon>-->
        <span>{{item.name}}</span>
      </template>
      <!-- 递归,实现无限级展开 -->
      <MenuTree :menuList="item.children"></MenuTree>
    </el-sub-menu>
    <el-menu-item v-else :key="item.id" :index="item.url">
<!--      <el-icon v-if="item.icon">-->
<!--        <component :is="item.icon"></component>-->
<!--      </el-icon>-->
<!--      <img v-if="item.icon" :src="item.icon" width="20" style="margin-right: 6px">-->
      <span v-html="item.icon" v-if="item.icon"></span>
      <span>{{item.name}}</span>
    </el-menu-item>
  </template>
</template>

<script>
export default {
  name: "MenuTree",
  props:{
    menuList:{
      type:Array,
      default(){
        return []
      }
    }
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>

</style>