<template>
  <el-row :gutter="20" style="height: 100%">
    <el-col :span="5">
      <div class="sub-menu item-car">
        <div class="left-tree-custMenu">
          <p class="title-p" style="margin-top: 24px;padding-left: 10px;box-sizing: border-box">公众号菜单</p>
          <el-tree :data="leftTreeList" :props="defaultProps" draggable @node-drop="dragSuccess"  :allow-drop="dropAllow"
          :expand-on-click-node="false"
		  :default-expand-all='true'
           @node-click="handleNodeClick"
          >
            <template #default="{ node, data }">
              <div class="custom-tree-node">
                <p>{{ node.label }}</p>
                <div>
                  <a @click.stop="appendTree(data)" v-if="data.inTop === 0">
                    <el-icon><Plus /></el-icon>
                  </a>
				  <div @click.native.stop style="display: inline;">
					  <el-popconfirm confirm-button-text="确定" cancel-button-text="取消"
					                 icon-color="red" title="确定删除吗?" @confirm="removeTree(node, data)">
					    <template #reference>
					      <a style="margin-left: 8px">
					        <el-icon style="color: #F56C6C;" >
					          <delete />
					        </el-icon>
					      </a>
					    </template>
					  </el-popconfirm>
				  </div>
                </div>
              </div>
            </template>
          </el-tree>

          <div class="addTree" @click="addTreeOne" v-if="leftTreeList.length < 3">
            <el-icon style="margin-right: 6px"><Plus /></el-icon>
            新增菜单
          </div>

        </div>
      </div>

    </el-col>
    <el-col :span="19">
      <div class="item-car" style="overflow:hidden;">
        <el-row :gutter="140" style="height: 100%">
          <el-col :span="14">
            <div class="item-car">
              <div class="from-box">
                <p class="title-p">
					{{nowTitle}}
				</p>
                <el-form :model="form" label-width="80px" style="margin-top: 20px"
                         :rules="rules"
                         ref="addform"
                         :inline-message="true"
                >
                  <el-form-item label="菜单名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入菜单名称"  />
                  </el-form-item>
                  <el-form-item label="">
                    <div class="input-tag">
                      <el-tag>一级菜单最多4个汉字，二级菜单最多8个汉字</el-tag>
                    </div>
                  </el-form-item>
                  <el-form-item label="菜单内容">
                    <el-radio-group v-model="form.content">
                      <el-radio label="click" size="large">发送消息</el-radio>
                      <el-radio label="view" size="large">跳转网页</el-radio>
                      <el-radio label="miniprogram" size="large">跳转小程序</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-form>

<!--                单选框选择展示区-->
<!--                发送消息-->
                <div class="radio-box" v-show="form.content === 'click'">
                  <el-tabs class="demo-tabs" v-model="activeName">
                    <el-tab-pane label="图文消息" name="first">图文消息</el-tab-pane>
                    <el-tab-pane label="图片" name="second">图片</el-tab-pane>
                    <el-tab-pane label="音频" name="third">音频</el-tab-pane>
                    <el-tab-pane label="视频" name="fourth">视频</el-tab-pane>
                    <el-tab-pane label="视频号动态" name="five">视频号动态</el-tab-pane>
                  </el-tabs>

                </div>
                <!--            jumpUrl    跳转网页-->
                <div class="radio-box" v-show="form.content === 'view'">
                  <el-form label-width="80px" style="margin-top: 20px"
                           
                  >
                    <el-form-item label="跳转地址" prop="name">
                      <el-input v-model="jumpUrl" type="textarea" autosize placeholder="请输入跳转地址"  />
                    </el-form-item>
                    <el-form-item label="">
                      <div class="input-tag">
                        <el-tag>输入点击后跳转的网路地址</el-tag>
                      </div>
                    </el-form-item>
                  </el-form>

                </div>
                <!--                跳转小程序-->
                <div class="radio-box" v-show="form.content === 'miniprogram'">
                  跳转小程序

                </div>

<!--                保存按钮-->
                <div class="btn-box">
                  <el-row class="mb-4">
                    <el-button color="#0052D9" @click="formSubimt">保  存</el-button>
                    <el-button type="info" @click="resetFrom">重  置</el-button>
                  </el-row>
                </div>

              </div>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="from-box">
              <div class="item-car" style="height:100%">
                <p class="title-p">设置预览</p>
				<div class="show-iphone-box" style="width: 100%;">
					<div class="show-iphone-item">
						<div class="iphone-nav">
						
						  <template v-for="(item,index) in leftTreeList" :key="index">
						    <div class="separator"></div>
						    <div class="nav-item">
						      {{item.name}}
						      <div class="item-cent" v-if="item.sub_button">
						        <div class="title-cent" v-for="(itemc,ind) in item.sub_button" :key="ind">
						          {{itemc.name}}
						        </div>
						      </div>
						    </div>
						  </template>
						
						</div>
					</div>
				</div>
                <!-- <div class="show-iphone">
                  <div class="iphone-nav">

                    <template v-for="(item,index) in leftTreeList" :key="index">
                      <div class="separator"></div>
                      <div class="nav-item">
                        {{item.name}}
                        <div class="item-cent" v-if="item.sub_button">
                          <div class="title-cent" v-for="(itemc,ind) in item.sub_button" :key="ind">
                            {{itemc.name}}
                          </div>
                        </div>
                      </div>
                    </template>



                  </div>
                </div> -->
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "customizeMenu",
  data() {
    const validatePass = (rule, value, callback) => {
      if ((this.isTopLevel === true && getByteLength(value) <= 8) || (this.isTopLevel === false && getByteLength(value) <= 16)) {
        callback();
      } else {
        callback(new Error("菜单名称长度超出限制"));
      }
    };
    var getByteLength = (str)=> {
      let len = 0;
      for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94) {
          len += 2;
        } else {
          len++;
        }
      }
      return len;
    }

    return {
      defaultProps:{
        children: 'sub_button',
        label: 'name',
      },
      activeName:'first',
      form:{
        name:'',
        content:'view'
      },
      rules:{
        name:[
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
          { required: true, trigger: "blur", validator: validatePass }
        ]
      },
      leftTreeList:[
        // {
        //   name:'一级菜单1',
        //   sub_button:[
        //     {
        //       name:'一子菜单1',
        //     },{
        //       name:'一子菜单2',
        //     }
        //   ]
        // },
        // {
        //   name:'一级菜单2',
        //   sub_button:[
        //     {
        //       name:'二子菜单1',
        //     },{
        //       name:'二子菜单2',
        //     }
        //   ]
        // }
      ],
      isTopLevel:true,
      nowTreeName:'',
      isUpdate:false,
      nowUpdateData:{},
	  jumpUrl:'',
	  nowTitle:'菜单管理'
    }
  },
  methods: {
    resetFrom(){
      this.nowTitle = '菜单管理'
      this.isTopLevel = true;
      this.isUpdate = false;
      this.jumpUrl = '';
      this.form = {
        name:'',
        content:'view'
      }
      this.nowTreeName = '';
    },
    addTreeOne(){
	  this.nowTitle = '新增一级菜单'
      this.isTopLevel = true;
	  this.isUpdate = false;
	  this.jumpUrl = '';
      this.form = {
        name:'',
        content:'view'
      }
	  this.nowTreeName = '';
    },
    appendTree(data){
      this.isTopLevel = false;
	  this.isUpdate = false;
	   this.nowTitle = `添加【${data.name}】的二级菜单`
      // console.log(data)
	  this.jumpUrl = '';
	  this.nowTreeName = '';
      this.form = {
        name:'',
        content:'view'
      }
      this.nowTreeName = data.name;
    },
    removeTree(node,data){
      // console.log(node)
      // console.log(data)
      this.leftTreeList =  this.removeDg(this.leftTreeList,data.name,node);
      this.leftTreeList =JSON.parse(JSON.stringify(this.leftTreeList));
      var buttonJson = {
        button:this.leftTreeList
      }
      // console.log(buttonJson)
      requestfaceWithHeadersfn("wechat/index.php","createWechatMenu",{keyword:'createWechatMenu',menu_json:JSON.stringify(buttonJson)},"01").then((data)=>{
        //数据返回成功
        if(data.success){
          this.$message({
            message: data.msg,
            type: "success",
          });
		  this.nowTitle = '菜单管理'
		  this.isTopLevel = true;
		  this.isUpdate = false;
		  this.jumpUrl = '';
		  this.form = {
		    name:'',
		    content:'view'
		  }
		  this.nowTreeName = '';
        }else {
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    removeDg(list,name,node){
      for (let i = 0; i < list.length; i++) {
        if(list[i].sub_button && list[i].sub_button.length > 0 && node.level == 2){
          list[i].sub_button = this.removeDg(list[i].sub_button,name);
        }else {
          if(list[i].name == name){
            list.splice(i,1);
            break;
          }
        }
      }
	  // 如果sub_button子集为空则需要 删除子集属性
	  for (var i = 0; i < list.length; i++) {
	  	if (list[i].sub_button && list[i].sub_button.length == 0) {
			list[i].type = 'view';
			list[i].url = 'https://www.baidu.com';
	  		delete list[i].sub_button
	  	}
	  }
      return list
    },
    // 拖拽时触发
    dropAllow(draggingNode, dropNode, type) {
      if (draggingNode.level === dropNode.level) {
        return type === "prev" || type === "next";
        // if (draggingNode.parent.id === dropNode.parent.id) {
        //   // 向上拖拽 || 向下拖拽
        //   return type === "prev" || type === "next";
        // }
      } else {
        return type === "inner" || this.leftTreeList.length < 3;
      }
    },
    // 拖拽成功时触发
    dragSuccess(draggingNode, dropNode, type, event) {
      // console.log(draggingNode, dropNode, type, event);
      //提交保存的左侧菜单
      this.createWechatMenu();
    },
    formSubimt(){
      if(this.leftTreeList.length == 3 && this.isTopLevel){
        this.$message({
          message: "一级菜单最多只能有三个",
          type: "warning",
        });
        return
      }
      this.$refs["addform"].validate((valid) => {
        if(valid){
          if(this.isUpdate){
            this.UpdateTree();
            return
          }
          var addbutton = {
            type:this.form.content,
            name:this.form.name,
            url:this.jumpUrl?this.jumpUrl:'https://www.baidu.com'
          };
		  if (this.nowTreeName) {
		  	for (let i = 0; i < this.leftTreeList.length; i++) {
		  	  if(this.leftTreeList[i].name == this.nowTreeName){
				  if (this.leftTreeList[i].sub_button && this.leftTreeList[i].sub_button.length >= 5) {
				  	this.$message({
				  	  message: "二级菜单最多只能有五个",
				  	  type: "warning",
				  	});
				  	return
				  }
		  	    this.leftTreeList[i].sub_button?this.leftTreeList[i].sub_button.push(addbutton):this.leftTreeList[i].sub_button =[addbutton];
				
		  	  }
		  	}
		  }else{
			  addbutton.inTop = 0 ;
			  this.leftTreeList.push(addbutton)
		  }
          
          this.leftTreeList =JSON.parse(JSON.stringify(this.leftTreeList));
          //提交保存的左侧菜单
          this.createWechatMenu();
        }
      })
    },
    createWechatMenu(){
      // for (let i = 0; i < this.leftTreeList.length; i++) {
      //   if(this.leftTreeList[i].sub_button && this.leftTreeList[i].sub_button.length > 5){
      //     this.$message({
      //       message: '二级菜单最多五个',
      //       type: "warning",
      //     });
      //   }
      //   return
      // }
      var buttonJson = {
        button:this.leftTreeList
      }
      requestfaceWithHeadersfn("wechat/index.php","createWechatMenu",{keyword:'createWechatMenu',menu_json:JSON.stringify(buttonJson)},"01").then((data)=>{
        //数据返回成功
        // console.log(data)
        if(data.success){
          this.$message({
            message: data.msg,
            type: "success",
          });
          this.form = {
            name:'',
            content:'view'
          }
		  this.jumpUrl = '';
        }else {
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    getMenuList(){
      requestfaceWithHeadersfn("wechat/index.php","selectWechatMenu",{keyword:'selectWechatMenu'},"01").then((data)=>{
        //数据返回成功
        if(data.success){
          this.leftTreeList =  data.data.menuList.button;
          for (let i = 0; i < this.leftTreeList.length; i++) {
            this.leftTreeList[i].inTop = 0;
          }
          // console.log(this.leftTreeList)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    handleNodeClick(data, node, item){
      this.nowUpdateData = {
        data:data,
        node:node
      }
	  this.nowTitle = `编辑【${data.name}】`
	  if (data.url) {
	  	this.jumpUrl = data.url
	  }
	  if (data.inTop === 0) {
		  // 菜单长度限制
	  	this.isTopLevel = true;
	  }else{
		  this.isTopLevel = false;
	  }
      this.isUpdate = true;
      this.nowTreeName = data.name;
      this.form.name = data.name;
      this.form.content = data.type;
    },
    UpdateTree(data,node){
      data = this.nowUpdateData.data;
      node = this.nowUpdateData.node;
      if(node.level === 1){
        for (let i = 0; i < this.leftTreeList.length; i++) {
          if(this.leftTreeList[i].name == data.name){
            this.leftTreeList[i].name = this.form.name;
          }
        }
      }else if(node.level === 2){
        for (let i = 0; i < this.leftTreeList.length; i++) {
          if(this.leftTreeList[i].name == node.parent.data.name){
            for (let j = 0; j < this.leftTreeList[i].sub_button.length; j++) {
				// 更新名称
              if(this.leftTreeList[i].sub_button[j].name == data.name){
                this.leftTreeList[i].sub_button[j].name = this.form.name;
              }
			  // 更新跳转地址
			  if(this.leftTreeList[i].sub_button[j].name == data.name && this.jumpUrl){
			    this.leftTreeList[i].sub_button[j].url = this.jumpUrl;
			  }
			  
            }
          }
        }
      }

      //提交保存的左侧菜单
      this.createWechatMenu();

    }
  },
  mounted() {

    setTimeout(()=>{
      this.getMenuList()
    },500)
    // console.log(mainUrl)
  }
}
</script>

<style scoped>
.item-car{
  background-color: #fff;
  border-radius: 3px;
  height: 100%;
}
.sub-menu{
  padding: 5px 8px;
  box-sizing: border-box;
}
.custom-tree-node{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.addTree{
  color: #0052D9;
  line-height: 46px;
  text-align: left;
  box-sizing: border-box;
  padding-left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.from-box{
  width: 90%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  position: relative;
}
.input-tag{
  position: absolute;
  top: -15px;
  left: 0;
}
.radio-box{
  width: 98%;
  margin-left: 2%;
  margin-top: 20px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 0 5px #cccaca;
}
.show-iphone{
  width: 100%;
  height: calc(100% - 80px);
  /*aspect-ratio: 20/41;*/
  background: url("/img/iphone.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 20px;
  position: relative;
}
.separator{
  height: 85%;
  border-left: 1px solid #ededed;
}
.iphone-nav{
  width: 76%;
  height: 8%;
  position: absolute;
  bottom: 4%;
  right: 9%;
  display: flex;
  align-items: center;
}
.nav-item{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: relative;
}
.item-cent{
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 5px;
  white-space: nowrap;
  min-width: 60px;
}
.title-cent{
  line-height: 30px;
  border-top: 1px solid #ededed;
  font-size: 12px;
}
.btn-box{
  position: absolute;
  bottom: 15px;
  right: 6px;
}
.show-iphone-box{
	position: relative;
	width: 100%;

}
.show-iphone-item{
	 position: relative;
	 width: 100%;
	 background: url("/img/iphone.png") no-repeat;
	 background-size: 100% 100%;
}
.show-iphone-item::before{
		display: block;
		content: '';
		width: 100%;
		padding-top: 200%;
	}
</style>