import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import homeIndex from '../views/homeIndex.vue'
import customizeMenu from '../views/customizeMenu.vue'
import articlePublishing from '../views/articlePublishing.vue'
import classStyle from '../views/classStyle.vue'

const routes = [
	{
	  path: '/',
	  name: 'login',
	  component: () => import('../views/login.vue')
	},
  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect:'/home/homeIndex',
    children:[
      {
        path: 'homeIndex',
        name: 'homeIndex',
        component: () => import('../views/homeIndex.vue')
      },
      {
        path: 'customizeMenu',
        name: 'customizeMenu',
        component: () => import('../views/customizeMenu.vue')
      },
      {
        path: 'articlePublishing',
        name: 'articlePublishing',
        component: () => import('../views/articlePublishing.vue')
      },
      {
        path: 'classStyle',
        name: 'classStyle',
        component: () => import('../views/classStyle.vue')
      },
      {
        path: 'gradeActivities',
        name: 'gradeActivities',
        component: () => import('../views/gradeActivities.vue')
      },
      {
        path: 'originalComposition',
        name: 'originalComposition',
        component: () => import('../views/originalComposition.vue')
      },
      {
        path: 'excellentCommendation',
        name: 'excellentCommendation',
        component: () => import('../views/excellentCommendation.vue')
      },
      {
        path: 'classManagement',
        name: 'classManagement',
        component: () => import('../views/classManagement.vue')
      },
      {
        path: 'userManagement',
        name: 'userManagement',
        component: () => import('../views/userManagement.vue')
      },
      {
        path: 'officialAccountSetting',
        name: 'officialAccountSetting',
        component: () => import('../views/officialAccountSetting.vue')
      },
      {
        path: 'systemSettings',
        name: 'systemSettings',
        component: () => import('../views/systemSettings.vue')
      },
      {
        path: 'complaintsSuggestions',
        name: 'complaintsSuggestions',
        component: () => import('../views/complaintsSuggestions.vue')
      },
      {
        path: 'PersonalCenter',
        name: 'PersonalCenter',
        component: () => import('../views/PersonalCenter.vue')
      },
      {
        path: 'automaticReply',
        name: 'automaticReply',
        component: () => import('../views/automaticReply.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
