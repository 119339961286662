import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElIconModules from '@element-plus/icons'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import CKEditor from '@ckeditor/ckeditor5-vue';
const app = createApp(App)
// 统一注册Icon图标
for (const iconName in ElIconModules) {
    if (Reflect.has(ElIconModules, iconName)) {
        const item = ElIconModules[iconName]
        app.component(iconName, item)
    }
}
// 前置路由守卫
router.beforeEach((to, from, next) => {
	if (sessionStorage.getItem('ucode') || to.name ==='login') {
		next();
	}else{
		next('/');
	}
})

app.use(store).use(router).use( CKEditor).use(ElementPlus, {
    locale: zhCn,
}).mount('#app')
//发布的时候 注释掉console.log
if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
}
