<template>
  <div class="home">
    <el-row style="height: 100%;overflow: hidden;padding-bottom: 15px;">
      <el-col :span="4">
        <div class="grid-nav">
          <div class="logo-title">
            <img :src="logoImg">
            <span>{{ titleNameL }}</span>
          </div>
          <el-menu
              :default-active="defaultMenu"
              class="el-menu-vertical-demo"
              text-color="#757575"
              active-text-color="#fff"
              @select="menuRouter"
          >
            <MenuTree :menuList="leftNavList"></MenuTree>
          </el-menu>
        </div>
      </el-col>
      <el-col :span="20" style="height: 100%">
        <div class="grid-content">
          <div class="top-wrap">
<!--            切换公众号-->
            <el-popover
                placement="bottom"
                :width="300"
                trigger="click"
            >
              <template #reference>
					<span style="cursor: pointer;margin-right: 30px">
						{{ nowAppNameL }}
						<el-icon style="margin-top: 3px;vertical-align: top;"><ArrowDown/></el-icon>
					</span>
              </template>
              <template #default>
                <ul class="ucode-ul">
                  <li v-for="(item,index) in clasTableData"
                      :key="index"
                      @click="checkOfficial(item)"
                      :class="{'gzActive':item.app_guid === nowAppid}">
                    <p class="check-gzhtext" :title="item.app_name">{{item.app_name}}</p>
                    <div style="font-size: 22px;line-height: 0;display: none"><el-icon color="#409EFF"><Check /></el-icon></div>
                  </li>
                </ul>
              </template>
            </el-popover>
<!--            退出登陆-->
            <el-popover
                placement="bottom"
                trigger="hover"
            >
              <template #reference>
					<span style="cursor: pointer;">
						{{ ucode.username }}
						<el-icon style="margin-top: 3px;vertical-align: top;"><ArrowDown/></el-icon>
					</span>
              </template>
              <template #default>
                <ul class="ucode-ul">
                  <li @click="personalCenter">
                    <el-icon>
                      <User/>
                    </el-icon>
                    个人中心
                  </li>
                  <li @click="logOut">
                    <el-icon>
                      <SwitchButton/>
                    </el-icon>
                    退出登录
                  </li>
                </ul>
              </template>
            </el-popover>

          </div>
        </div>
        <div class="router-div">
          <router-view/>
        </div>
      </el-col>

    </el-row>
  </div>
</template>

<script>
import MenuTree from '@/components/menustree.vue';
import {ElMessage , ElNotification } from "element-plus";

export default {
  name: 'Home',
  components: {
    MenuTree
  },
  data() {
    return {
      clasTableData:[],
      nowAppid:sessionStorage.getItem('app_guid'),
      nowAppNameL:JSON.parse(sessionStorage.getItem('ucode')).app_name || '',
      logoImg: '/img/logo.png',
      titleNameL: '济宁市育才中学',
      defaultMenu: sessionStorage.getItem('routerUrl') || '/homeIndex',
      ucode: JSON.parse(sessionStorage.getItem('ucode')) || 'user',
      leftNavList: [
        {
          id: '1',
          name: '首页',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15.265" height="15.883" viewBox="0 0 15.265 15.883">
  <path id="路径_7" data-name="路径 7" d="M1.388,6.148v8.311H13.877v-8.3L7.43,1.464ZM.559,4.99,6.6.306A1.344,1.344,0,0,1,8.226.282l6.447,4.7a1.462,1.462,0,0,1,.592,1.183v8.3A1.417,1.417,0,0,1,13.877,15.9H1.388A1.417,1.417,0,0,1,0,14.459V6.148A1.464,1.464,0,0,1,.559,4.99ZM5.353,9.965a.708.708,0,0,0-.694.722V10.7a.708.708,0,0,0,.694.722H9.912a.708.708,0,0,0,.694-.722v-.017a.708.708,0,0,0-.694-.722H5.353Z" transform="translate(0 -0.021)" fill="#757575"/>
</svg>`,
          url: '/homeIndex',
        },
        {
          id: '2',
          name: '公众号管理',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="17.77" height="15.207" viewBox="0 0 17.77 15.207">
  <g id="组_1286" data-name="组 1286" transform="translate(-19.691 -105.759)">
    <path id="路径_69" data-name="路径 69" d="M212.88,264.135a1.061,1.061,0,0,1-1.1-.975,1.049,1.049,0,0,1,1.1-.975.976.976,0,0,1,0,1.95Zm-4.17,0a.982.982,0,1,1,0-1.95.976.976,0,0,1,0,1.95Z" transform="translate(-184.532 -153.5)" fill="rgba(0,0,0,0.6)"/>
    <path id="路径_70" data-name="路径 70" d="M35.348,120.966a.455.455,0,0,1-.223-.058l-1.471-.84a7.119,7.119,0,0,1-1.722.28,5.562,5.562,0,0,1-5.138-3.128.223.223,0,0,1-.074.01q-.3.029-.6.029a8.166,8.166,0,0,1-1.834-.251l-.186-.038-1.936,1a.412.412,0,0,1-.214.048.452.452,0,0,1-.307-.116.514.514,0,0,1-.14-.521l.484-1.526a5.484,5.484,0,0,1-2.3-4.355c0-3.167,2.886-5.745,6.423-5.745,3.109,0,5.874,2.008,6.46,4.673a5.236,5.236,0,0,1,4.887,4.934A5.048,5.048,0,0,1,35.45,119.1l.354,1.226a.5.5,0,0,1-.158.521.448.448,0,0,1-.3.116Zm-1.638-1.9a.454.454,0,0,1,.223.058l.633.357-.121-.406a.5.5,0,0,1,.167-.531,4.216,4.216,0,0,0,1.9-3.167c0-2.163-2.1-4-4.589-4-2.532,0-4.6,1.8-4.6,4s2.066,4,4.6,4a6.864,6.864,0,0,0,1.675-.29.452.452,0,0,1,.112-.019Zm-7.6-12.32c-3.025,0-5.492,2.143-5.492,4.77a4.6,4.6,0,0,0,2.187,3.775.5.5,0,0,1,.177.55l-.242.744,1.071-.56a.529.529,0,0,1,.2-.048.3.3,0,0,1,.093.01l.335.068a7.439,7.439,0,0,0,1.657.232c.121,0,.251,0,.372-.01a4.266,4.266,0,0,1-.093-.9,5.263,5.263,0,0,1,5.2-4.963,5.606,5.606,0,0,0-5.473-3.669Z" fill="rgba(0,0,0,0.6)"/>
    <path id="路径_71" data-name="路径 71" d="M564.094,503.882a.821.821,0,0,1,0-1.641.828.828,0,1,1,0,1.641Zm3.277,0a.821.821,0,0,1,0-1.641.89.89,0,0,1,.949.821A.9.9,0,0,1,567.37,503.882Z" transform="translate(-533.81 -389.067)" fill="rgba(0,0,0,0.6)"/>
  </g>
</svg>`,
          url: '',
          children: [
            {
              id: '2-1',
              name: '班级风貌',
              icon: '',
              url: '/classStyle',
            },
            {
              id: '2-2',
              name: '年级活动',
              icon: '',
              url: '/gradeActivities',
            },
            {
              id: '2-3',
              name: '原创作文',
              icon: '',
              url: '/originalComposition',
            },
            {
              id: '2-4',
              name: '优秀表彰',
              icon: '',
              url: '/excellentCommendation',
            },
            {
              id: '2-5',
              name: '投诉建议',
              icon: '',
              url: '/complaintsSuggestions',
            }
          ]
        },
        {
          id: '3',
          name: '内容管理',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="14.543" height="14.893" viewBox="0 0 14.543 14.893">
        <g id="组_1285" data-name="组 1285" transform="translate(-64.094)">
          <path id="路径_67" data-name="路径 67" d="M78.536,3.679,75.3.146A.469.469,0,0,0,74.959,0H65.68a1.515,1.515,0,0,0-1.586,1.423V13.47a1.515,1.515,0,0,0,1.586,1.423H77.052a1.511,1.511,0,0,0,1.584-1.425V3.932a.375.375,0,0,0-.1-.253ZM74.47.931a.469.469,0,0,1,.344.146l2.442,2.67H74.47ZM77.6,13.47a.524.524,0,0,1-.548.492H65.68a.524.524,0,0,1-.548-.492V1.423A.524.524,0,0,1,65.68.931h7.752v2.92a.879.879,0,0,0,.921.827H77.6V13.47Z" transform="translate(0)" fill="rgba(0,0,0,0.6)"/>
          <path id="路径_68" data-name="路径 68" d="M255.226,260.568a.517.517,0,0,0,.551.475H263.5a.481.481,0,1,0,0-.951h-7.722A.517.517,0,0,0,255.226,260.568Zm8.274,2.411h-7.722a.481.481,0,1,0,0,.951H263.5a.481.481,0,1,0,0-.951Zm-7.722-4.755h2.956a.481.481,0,1,0,0-.951h-2.956a.481.481,0,1,0,0,.951Z" transform="translate(-188.29 -253.589)" fill="rgba(0,0,0,0.6)"/>
        </g>
      </svg>`,
          url: '/articlePublishing',
        },
        {
          id: '4',
          name: '班级管理',
          icon: `<svg id="组_1284" data-name="组 1284" xmlns="http://www.w3.org/2000/svg" width="14.543" height="15.207" viewBox="0 0 14.543 15.207">
  <path id="路径_65" data-name="路径 65" d="M13.116,136.076H1.428A1.523,1.523,0,0,1,0,134.477V124.29a1.523,1.523,0,0,1,1.427-1.6H13.116a1.523,1.523,0,0,1,1.427,1.6v10.187A1.523,1.523,0,0,1,13.116,136.076ZM1.428,123.79a.477.477,0,0,0-.447.5v10.186a.477.477,0,0,0,.447.5H13.116a.477.477,0,0,0,.447-.5V124.29a.477.477,0,0,0-.447-.5Z" transform="translate(0 -120.869)" fill="rgba(0,0,0,0.6)"/>
  <path id="路径_66" data-name="路径 66" d="M154.907,5.379a.513.513,0,0,1-.466-.549V.549a.472.472,0,1,1,.932,0V4.83a.513.513,0,0,1-.466.549Zm7.518.033a.513.513,0,0,1-.466-.549V.582a.472.472,0,1,1,.932,0V4.863a.513.513,0,0,1-.466.549Zm-3.657,0a.513.513,0,0,1-.466-.549V.582a.472.472,0,1,1,.932,0V4.863a.513.513,0,0,1-.466.549Zm4.185,2.812h-8.915a.556.556,0,0,1,0-1.1h8.915a.556.556,0,0,1,0,1.1Zm0,2.564h-8.915a.556.556,0,0,1,0-1.1h8.915a.556.556,0,0,1,0,1.1Z" transform="translate(-151.291)" fill="rgba(0,0,0,0.6)"/>
</svg>`,
          url: '/classManagement',
        },
        {
          id: '5',
          name: '用户管理',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="14.543" height="15.207" viewBox="0 0 14.543 15.207">
  <path id="路径_64" data-name="路径 64" d="M132.914,110.969a5.069,5.069,0,1,0-10.138,0,4.917,4.917,0,0,0,2.592,4.394,6.822,6.822,0,0,0-4.225,5.069.57.57,0,0,0,.45.675h.113a.548.548,0,0,0,.563-.45,5.719,5.719,0,0,1,5.519-4.619h.113A5.109,5.109,0,0,0,132.914,110.969Zm-9.013,0a3.942,3.942,0,0,1,7.884,0,3.982,3.982,0,0,1-3.886,3.944h-.169A4.007,4.007,0,0,1,123.9,110.969Zm7.83,5.071a.532.532,0,0,0,.563.563h2.817a.563.563,0,1,0,0-1.125h-2.815A.562.562,0,0,0,131.73,116.04Zm3.379,1.633H130.6a.563.563,0,1,0,0,1.125h4.506a.563.563,0,1,0,0-1.125Zm0,2.252h-4.448a.563.563,0,1,0,0,1.125h4.45a.532.532,0,0,0,.563-.563A.565.565,0,0,0,135.11,119.926Zm0,0" transform="translate(-121.131 -105.9)" fill="rgba(0,0,0,0.6)"/>
</svg>`,
          url: '/userManagement',
        },
        {
          id: '6',
          name: '公众号菜单',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15.206" height="15.207" viewBox="0 0 15.206 15.207">
  <path id="路径_63" data-name="路径 63" d="M104.95,98.93h-3.67a1.652,1.652,0,0,0-1.65,1.65v3.67a1.652,1.652,0,0,0,1.65,1.65h3.67a1.652,1.652,0,0,0,1.65-1.65v-3.67A1.651,1.651,0,0,0,104.95,98.93Zm.55,5.32a.551.551,0,0,1-.55.55h-3.67a.551.551,0,0,1-.55-.55v-3.67a.551.551,0,0,1,.55-.55h3.67a.551.551,0,0,1,.55.55Zm-.55,2.918h-3.67a1.652,1.652,0,0,0-1.65,1.65v3.67a1.652,1.652,0,0,0,1.65,1.65h3.67a1.652,1.652,0,0,0,1.65-1.65v-3.67A1.652,1.652,0,0,0,104.95,107.168Zm.55,5.32a.551.551,0,0,1-.55.55h-3.67a.551.551,0,0,1-.55-.55v-3.67a.551.551,0,0,1,.55-.55h3.67a.551.551,0,0,1,.55.55Zm7.687-13.557h-3.67a1.652,1.652,0,0,0-1.65,1.65v3.67a1.652,1.652,0,0,0,1.65,1.65h3.67a1.652,1.652,0,0,0,1.65-1.65v-3.67A1.651,1.651,0,0,0,113.187,98.93Zm.55,5.32a.551.551,0,0,1-.55.55h-3.67a.551.551,0,0,1-.55-.55v-3.67a.551.551,0,0,1,.55-.55h3.67a.551.551,0,0,1,.55.55Zm-.55,2.918h-3.67a1.652,1.652,0,0,0-1.65,1.65v3.67a1.652,1.652,0,0,0,1.65,1.65h3.67a1.652,1.652,0,0,0,1.65-1.65v-3.67A1.652,1.652,0,0,0,113.187,107.168Zm.55,5.32a.551.551,0,0,1-.55.55h-3.67a.551.551,0,0,1-.55-.55v-3.67a.551.551,0,0,1,.55-.55h3.67a.551.551,0,0,1,.55.55Z" transform="translate(-99.63 -98.93)" fill="rgba(0,0,0,0.6)"/>
</svg>`,
          url: '/customizeMenu',
        },
        {
          id: '7',
          name: '系统管理',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16.093" height="15.207" viewBox="0 0 16.093 15.207">
  <g id="组_1209" data-name="组 1209" transform="translate(-101.471 -124.08)">
    <path id="路径_23" data-name="路径 23" d="M117.4,130.112c-.1-.505-.406-.832-.8-.832h-.069a1.947,1.947,0,0,1-1.945-1.946,2.246,2.246,0,0,1,.169-.741,1.052,1.052,0,0,0-.354-1.271l-2.013-1.121-.03-.014a1.188,1.188,0,0,0-1.254.25,2.861,2.861,0,0,1-1.538.863,2.858,2.858,0,0,1-1.548-.88,1.182,1.182,0,0,0-1.253-.259l-2.085,1.142-.031.02a1.05,1.05,0,0,0-.356,1.268,2.213,2.213,0,0,1,.17.743,1.947,1.947,0,0,1-1.945,1.946h-.081c-.385,0-.695.327-.79.832a7.457,7.457,0,0,0,0,3.152c.1.506.406.833.8.833h.069a1.947,1.947,0,0,1,1.945,1.945,2.222,2.222,0,0,1-.17.741,1.051,1.051,0,0,0,.353,1.27l1.975,1.108.03.014a1.162,1.162,0,0,0,1.259-.264,2.866,2.866,0,0,1,1.572-.917,2.873,2.873,0,0,1,1.582.936,1.147,1.147,0,0,0,.837.357,1.061,1.061,0,0,0,.421-.085l2.048-1.129.03-.019a1.05,1.05,0,0,0,.356-1.269,2.217,2.217,0,0,1-.17-.743,1.947,1.947,0,0,1,1.945-1.945h.08c.385,0,.7-.327.791-.833a7.459,7.459,0,0,0,0-3.152m-1,1.576a8.5,8.5,0,0,1-.129,1.25,3.112,3.112,0,0,0-2.857,3.1,3.26,3.26,0,0,0,.223,1.1l-1.747.965a5.681,5.681,0,0,0-.685-.6,2.536,2.536,0,0,0-3.442-.011,5.28,5.28,0,0,0-.681.592l-1.68-.941a3.231,3.231,0,0,0,.224-1.1,3.112,3.112,0,0,0-2.856-3.1,6.122,6.122,0,0,1,0-2.5,3.112,3.112,0,0,0,2.856-3.1,3.263,3.263,0,0,0-.223-1.1l1.79-.984a5.538,5.538,0,0,0,.683.576,2.543,2.543,0,0,0,3.359.012,5.946,5.946,0,0,0,.681-.564l1.723.957a3.228,3.228,0,0,0-.224,1.1,3.112,3.112,0,0,0,2.857,3.1,8.545,8.545,0,0,1,.129,1.251" transform="translate(0 0)" fill="rgba(0,0,0,0.6)"/>
    <path id="路径_24" data-name="路径 24" d="M367.023,365.633a2.863,2.863,0,1,0,2.863,2.863,2.866,2.866,0,0,0-2.863-2.863m1.7,2.863a1.7,1.7,0,1,1-1.7-1.7,1.7,1.7,0,0,1,1.7,1.7" transform="translate(-257.54 -236.819)" fill="rgba(0,0,0,0.6)"/>
  </g>
</svg>`,
          url: ``,
          children: [
            {
              id: '7-1',
              name: '公众号设置',
              icon: '',
              url: '/officialAccountSetting',
            } ,
            {
              id: '7-3',
              name: '关键字回复',
              icon: '',
              url: '/automaticReply',
            }
            ,
            {
              id: '7-2',
              name: '系统设置',
              icon: '',
              url: '/systemSettings',
            }
          ]
        }
      ]
    }
  },
  methods: {
    menuRouter(index) {
      sessionStorage.setItem('routerUrl', index);
      this.$router.push('/home' + index);
    },
    personalCenter() {
      sessionStorage.setItem('routerUrl', '/PersonalCenter');
      this.$router.push('/home/PersonalCenter');
    },
    logOut() {
      sessionStorage.removeItem('routerUrl');
      sessionStorage.removeItem('ucode');
      sessionStorage.removeItem('app_guid');
      this.$router.push('/');
    },
    checkOfficial(item){
      this.nowAppid = item.app_guid;
      this.nowAppNameL = item.app_name;
      sessionStorage.setItem('app_guid',item.app_guid);
      ElNotification({
        title: 'Success',
        dangerouslyUseHTMLString: true,
        message: `<strong>已切换至<i style="color: teal;margin-left: 15px">${item.app_name}</i></strong>`,
        type: 'success',
      })
    },
    getUserInfo(){
      requestfaceWithHeadersfn("wechat/index.php","getWechatList",{
        keyword:'getWechatList',
        page:'1',
      },"01").then((data)=>{
        console.log(data);
        //数据返回成功
        if(data.success){
          this.clasTableData = data.data.list;
        }else{
          ElMessage.error(data.msg)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    }
  },
  mounted() {
    this.getUserInfo();
  }
}
</script>
<style>
.home {
  height: 100%;
  width: 100%;
}

.el-menu-item.is-active {
  background: #0052D9;
  border-radius: 15px;
  border: solid 7px #fff;
}

.el-menu-item {
  border-radius: 15px;
  border: solid 7px #fff;
}

.el-sub-menu {
  border: solid 7px #fff;
}

.el-sub-menu .el-menu-item {
  min-width: auto !important;
}

.logo-title {
  width: 100%;
  display: flex;
  height: 72px;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  color: #303133;
}

.logo-title > img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-right: 20px;
}

.el-menu {
  border-right: none !important;
}

.grid-content {
  width: 100%;
}

.grid-content .el-icon {
  cursor: pointer;
}

.top-wrap {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-right: 50px;
}

.router-div {
  width: 100%;
  height: calc(100% - 53px);
  background: #f2f4f5;
  padding: 18px 24px;
  box-sizing: border-box;
  overflow: auto;
}

.title-p {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}

.ucode-ul {
  width: 100%;
  list-style: none;
  max-height: 500px;
  overflow: auto;
}

.ucode-ul li {
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;
  font-size: 15px;
  cursor: pointer;
}

.ucode-ul li:hover {
  background-color: var(--el-menu-hover-bg-color);
  border-radius: 5px;
}

.left-tree-custMenu .el-tree-node__expand-icon {
  font-size: 14px !important;
}

.left-tree-custMenu .el-tree-node__content .el-tree-node__label {
  font-size: 16px !important;
  font-weight: bold;
  width: 100%;
}

.left-tree-custMenu .el-tree-node__label {
}

.left-tree-custMenu .el-tree-node__children .el-tree-node__label {
  font-weight: normal;
}

.el-menu-vertical-demo .el-menu-item:hover {
  color: #757575;
}

.el-menu-vertical-demo .el-menu-item > span > svg {
  vertical-align: middle;
  margin-right: 6px;
}

.el-menu-vertical-demo .el-sub-menu__title span > svg {
  vertical-align: middle;
  margin-right: 6px;
}

.el-menu-vertical-demo .is-active > span > svg path {
  fill: #fff;
}
.check-gzhtext{
  width: 85%;
  overflow: hidden; /* 超出一行文字自动隐藏 */
  text-overflow: ellipsis; /* 文字隐藏后添加省略号 */
  white-space: nowrap; /* 强制文本不换行 */
}
.gzActive>div{
  display: block !important;
}
</style>